@import 'css/variables.scss';

.asset-preview {
  border-radius: 14px;
  border: 1px solid $transparent-light;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
  background: none;
}

.item-image {
  border-radius: 8px 8px 0 0;
  box-sizing: content-box;
  // border: 1px solid $transparent-dark;
}

.item-image-label {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  background: $transparent-light;
  margin-top: 0.15rem;
  padding: 0.5rem 1.25rem;
  width: 100%;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
