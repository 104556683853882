@import 'css/variables.scss';

// Background Images
.BgBig1,
.BgSmall2 {
  display: none;
}
.BgSmall1 {
  width: 350px;
  transform: translate(-70%, 20%);
}
.BgBig2 {
  bottom: 0;
  transform: translate(40%, 10%);
  width: 350px;
}

// TEXT

@media screen and (max-width: 730px) {
  .magic-login {
    margin: 0 auto;
    max-width: 400px;
    // width: 100%;
  }

  .logo {
    width: 180px;
  }
}
@media screen and (max-width: 430px) {
  .magic-login {
    div > h1 {
      font-size: 2.25em;
    }
    form > label > h2 {
      font-size: 1.25em;
    }
    div > .text-secondary {
      font-size: smaller;
    }
  }
}
@media screen and (max-width: 430px) {
  .magic-login {
    div > h1 {
      font-size: 2em;
    }
  }
}

// MOBILE
.magic-login {
  text-align: center;
  width: 100%;
  height: 100%;
  place-items: center;
  display: grid;
  grid-template-rows: 1fr auto;

  padding-top: 49px;
  h1 {
    font-size: 3em;
    // font-weight: bold;
  }
  .logo {
    width: 140px;
  }
}

.login-form-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
}

.input-wrapper {
  padding: 2px;
  position: relative;
  height: 100%;
  border-radius: 13px;
  overflow: hidden;

  .input-background {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  input {
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    border-radius: 11px;
    background: #0c1226;
    color: #a4a4a4;
    outline: none;
  }
}

.login-submit-button {
  background: transparent;
  padding: 8px 0;
  width: 49px;
}

.loader {
  height: 49px;
  animation: spin 2s linear infinite;
  transform: rotate(0deg);
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
