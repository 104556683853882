.Toastify__toast {
  // border-radius: 20px;
}
.Toastify__close-button {
  color: black;
}
.Toastify__toast--info {
  background-color: rgba(223, 224, 225, 1);
  color: black;
}
.Toastify__toast--success {
  background-color: rgba(26, 237, 194, 1);
  color: black;
}
.Toastify__toast--warning {
  background-color: rgba(233, 210, 1, 1);
  color: black;
}
.Toastify__toast--error {
  background-color: rgba(233, 1, 71, 1);
  // color: black;
}
