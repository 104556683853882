// https://coolors.co/1e1e1e-ffffff-00ff00-2191fb-d80032

$black: #383838;
$white: #ffffff;

$lightGray: lighten(
  $color: $black,
  $amount: 74,
);
$lighterGray: lighten(
  $color: $black,
  $amount: 85,
);

// Typography
$darkText: lighten(
  $color: $black,
  $amount: 10,
);
$lightText: lighten(
  $color: $darkText,
  $amount: 10,
);
$lighterText: lighten(
  $color: $darkText,
  $amount: 25,
);

// BOX SHADOWS
$box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
$box-shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
$box-shadow-light: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
  0 1px 2px 0 rgba(0, 0, 0, 0.06);
$box-shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
// BOX SHADOWS

// NEW
$border-radius-px: 20px;

// Backgrounds
$transparent-light: #6e779530;
$transparent-dark: #23283bef;

$solid-dark: #0c1226;

// Text
$blue-grey: #90a0d3;

$rara-teal: #0decb6cc;
$rara-teal-2: #0decb688;
