@import 'css/variables.scss';

[data-reach-dialog-content] {
  z-index: 0;
  width: unset;
  margin: 10vh 0.667rem;
  background: white;
  padding: 0.667rem 0.667rem 1rem;
}

@media only screen and (min-width: 424px) {
  [data-reach-dialog-content] {
    width: 400px;
    margin: 10vh auto;
    border-radius: 24px;
  }
}

.icon {
  height: 1em;
  width: 1em;
}
