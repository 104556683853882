@import 'css/variables.scss';

/* Loaders */

// .lds-dual-ring {
//   display: inline-block;
//   width: 80px;
//   height: 80px;
// }

.lds-dual-ring {
  content: ' ';
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;

  border: 6px solid $rara-teal;
  border-color: $rara-teal transparent $rara-teal transparent;

  // background: #833ab4;
  // /* fallback for old browsers */
  // background: -webkit-linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);
  // /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to right, #fcb045, #fd1d1d, #833ab4);
  // /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  /* margin: 100px auto 0; */
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;

  background-color: $rara-teal-2;
}

//
// Bouncing
//

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  background-color: $rara-teal;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Loaders */
