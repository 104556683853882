.grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 1.5em 0;
}

.grid-2,
.grid-x-2,
.grid-3-x-x,
.grid-x-3-x,
.grid-3,
.grid-4,
.grid-2-3,
.grid-5-3,
.grid-3-5,
.grid-5,
.grid-1-3,
.grid-6-1 {
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media screen and (min-width: 900px) {
  /* swap grid order to alternate images */
  .swap-order {
    order: 2;
  }

  .grid-2 {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5em;
  }

  .grid-x-2 {
    grid-template-columns: auto 1fr;
    grid-gap: 1.5em;
  }

  .grid-x-3-x {
    grid-template-columns: auto 1fr auto;
    grid-gap: 1.5em;
  }

  .grid-3-x-x {
    grid-template-columns: 1fr auto auto;
    grid-gap: 1.5em;
  }

  .grid-2-x {
    grid-template-columns: 1fr auto;
    grid-gap: 1.5em;
  }

  .grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5em;
  }

  .grid-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1.5em;
  }

  .grid-1-3 {
    grid-template-columns: 1fr 3fr;
    grid-gap: 1.5em;
  }

  .grid-2-3 {
    grid-template-columns: 1fr 2fr;
    grid-gap: 1.5em;
  }

  .grid-5-3 {
    grid-template-columns: 5fr 3fr;
    grid-gap: 1.5em;
  }

  .grid-3-5 {
    grid-template-columns: 3fr 5fr;
    grid-gap: 1.5em;
  }

  .grid-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1.5em;
  }

  .grid-6-1 {
    grid-template-columns: 5fr 2fr;
    grid-gap: 1.5em;
  }
}
