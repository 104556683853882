@import 'css/variables.scss';

legend {
  //   font-family: 'Libre Baskerville';
  // color: $darkText;
  // font-size: 22px;
}

// form > hr {
//   margin: 1rem -1rem;
// }

fieldset {
  margin-bottom: 1.5rem;
}

// Buttons
// Buttons
// Buttons

// App code
button {
  outline: none;
  border: none;

  border-radius: $border-radius-px;
  padding: 8px 20px;
  cursor: pointer;

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: bold;
  align-items: center;
  text-align: center;

  color: $black;
}

button:disabled {
  color: $white;
}

.rounded {
  border-radius: 24px;
}

.btn {
  padding: 12px 25px;
  font-weight: 600;
  background: none;
  color: $lightGray;
  overflow: hidden;
  border-radius: 7px;
  border: none;
  position: relative;
  z-index: 9;
}

.btn:hover {
  color: white;
  text-decoration: none;
}

.btn-sm {
  padding: 10px 20px;
}

.btn.btn-sm:not(.btn-theme) {
  border: solid $black 1px;
  color: $black;
  font-weight: 400;
}

.btn.btn-theme {
  color: $white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;

  background: $black;
  border-bottom: none;
  box-shadow: $box-shadow-medium;

  transition: all 200ms;
}

.btn.btn-theme:hover {
  background: lighten($color: $black, $amount: 5);
}

.btn.btn-unstyled {
  background-color: inherit;
  padding: 2px 6px;
  // color: $lighterText;
  font-weight: unset;
}

.button-unstyled > * {
  pointer-events: none;
}

// Buttons
// Buttons
// Buttons

select,
option {
  font-size: small;
}

// Radio
// Radio
// Radio

.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  top: 10px;
  height: 30px;
  width: 30px;
  margin-right: 10px;
  color: $lightText;
  background-color: $white;
  border: solid 1px $black;
  border-radius: 50px;

  outline: none;
  cursor: pointer;
  transition: all 200ms;
}

.form-radio:checked::before {
  position: absolute;
  font: 13px/1 'Open Sans', sans-serif;
  left: 10px;
  top: 6px;
  content: '\02143';
  transform: rotate(40deg);
}

.form-radio:hover {
  border: solid 1px $black;
  color: $lightGray;
  content: '\02143';
}

.form-radio:checked {
  color: $white;
  background-color: $black;
  border: solid 1px $black;
}

.form-radio:disabled,
.form-control:disabled,
.form-control[readonly] {
  color: $darkText;
  border: 1px solid $lightGray;
  background-color: $lighterGray;
  box-shadow: $box-shadow-inner;
}

// Radio
// Radio
// Radio

// Input SLider
// Input SLider
// Input SLider

/* input range slider */
.input-range {
  margin: 36px 0 36px;
}

.input-range__label {
  color: $lighterText;
}

.input-range__track--active {
  background-color: $black;
}

.input-range__slider {
  border-color: $black;
  background-color: $black;
}

// Input SLider
// Input SLider
// Input SLider

.transfer-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  color: white;
}

.transfer-form-back-btn {
  background: white;
  border-color: lightgrey;
  height: 2rem;
  width: 2rem;
  align-self: center;
  margin: 0.25rem 0 0.25rem 0.25rem; // use tiny margin so click animation is visible
}

.transfer-form-send-btn {
  background: linear-gradient(to bottom right, #470dc3, #0d56c3);
  height: 3rem;
  width: 3rem;
}

.transfer-form-loading-info {
  position: absolute;
  bottom: 0.75rem;
  left: 2rem;
  width: 11rem;
  color: #90a0d3;
  background-color: #eeeeee;
  border: 1px solid;
  border-radius: 10px;
  text-align: center;
  font-size: 0.8em;
}

.form-item-header {
  color: $blue-grey;
  margin: 0 0 0 1rem;
  padding: 0;
  flex: 1;
}
