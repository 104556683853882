@import 'css/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@font-face {
  font-family: 'DM Sans-Bold';
  src: url('../assets/fonts/DMSans-Bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'DM Sans-BoldItalic';
  src: url('../assets/fonts/DMSans-BoldItalic.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'DM Sans-Italic';
  src: url('../assets/fonts/DMSans-Italic.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'DM Sans-Medium';
  src: url('../assets/fonts/DMSans-Medium.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'DM Sans-MediumItalic';
  src: url('../assets/fonts/DMSans-MediumItalic.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'DM Sans-Regular';
  src: url('../assets/fonts/DMSans-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Major Mono Display';
  src: url('../assets/fonts/MajorMonoDisplay-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Monoton';
  src: url('../assets/fonts/Monoton-Regular.ttf') format('truetype');
  font-display: swap;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

#root {
  min-height: 100vh;
}

@media only screen and (min-width: 424px) {
  #root {
    // min-height: 100vh;
  }
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  color: $white;
  font-size: 14px;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

i {
  display: inline-grid;
  vertical-align: -0.125rem;
}

p {
  line-height: 1.6;
}
hr {
  border-color: $blue-grey;
  border-style: dashed;
  border-width: 1px;
  margin-bottom: 1rem;
}

.highlight {
  background: #e901e9;
  background: -webkit-linear-gradient(to right, #e901e9 0%, #02ffc2 100%);
  background: -moz-linear-gradient(to right, #e901e9 0%, #02ffc2 100%);
  background: linear-gradient(to right, #e901e9 0%, #02ffc2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-box {
  // display: flex;
  // align-items: center;
  //width: 50vw;
  // width: 90%;
  // margin: auto;
  // max-width: 22em;

  position: relative;
  // padding: 30% 2em;
  box-sizing: border-box;

  $border: 3px;
  color: #fff;
  background: #000;
  background-clip: padding-box; /* !importanté */
  border: solid $border transparent; /* !importanté */
  border-radius: 25px;
  padding: 10px 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(to right, #e901e9 0%, #02ffc2 100%);
  }
}

.border-gradient {
  border-radius: 20px;
  border: 2px solid;
  border-width: 2px;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #e901e9 0%, #02ffc2 100%);
}

.light-background {
  color: $black;
  background-color: $white;
}

.text-secondary {
  color: $blue-grey;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mb-2 {
  margin-bottom: calc(1rem * 0.667);
}
.mb-3 {
  margin-bottom: 1rem;
}
// ----

.app {
  // background-image: linear-gradient(-197deg, #0c1226 60%, #0d56c3 100.1%);
  background: #0c1226;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;

  display: grid;
  grid-template-rows: auto 1fr;
}

.page-container {
  padding: 0 1rem;
  max-width: 100vw;
  min-width: 100vw;
  overflow: scroll;
}

@media screen and (min-width: 700px) {
  .page-container {
    padding: 0 40px;
  }
}

@media screen and (min-width: 1400px) {
  .page-container {
    width: 1400px;
    margin: 0 auto;
  }
}

.robot-label {
  // text-transform: uppercase;
  // font-family: monospace;
  color: $blue-grey;
  // margin: 8px 0 0;
  min-height: 1.7rem;
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  // -ms-hyphens: auto;
  // -moz-hyphens: auto;
  // -webkit-hyphens: auto;
  // hyphens: auto;
}
