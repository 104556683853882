@import 'css/variables.scss';

.ant-form {
  border-radius: $border-radius-px;
  padding: 0 1.5rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  .ant-form-item {
    width: 100%;
    @media (max-width: 575px) {
      flex-wrap: nowrap;
    }
    .ant-form-item-label {
      line-height: 1;
      white-space: initial;
      text-align: left;
      margin-bottom: 4px;
      @media (max-width: 575px) {
        flex: 0.4 0;
      }
    }
    .ant-form-item-control {
      flex: 0;
      padding: 0;
      margin: 0;
      max-width: 100%;
    }
  }
}

.ant-btn-primary {
  border-radius: 4px;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  // background: #f5f5f5;
  background: transparent;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-input[disabled] {
  color: $blue-grey;
  background-color: unset;
  border: unset;
  // border-left: 1px solid #d9d9d9;
  // border-bottom: 1px solid #d9d9d9;
  cursor: not-allowed;
  opacity: 1;
}

.ant-input-lg {
  padding: 6.5px 1rem;
}
.ant-input-number-input {
  padding: 6.5px 1rem;
}

// .ant-form-item {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   @media (max-width: 575px) {
//     flex-wrap: nowrap;
//     flex-direction: column;
//   }
//   // margin-bottom: 1rem;
//   // border: 1px solid;
// }

.ant-form-vertical .ant-form-item-label {
  padding: 0;
  // background-color: pink;
}

.ant-input-group-addon {
  background: transparent;
  border: none;
  padding: 0 0 0 1rem;
}

.ant-switch {
  // background: white;
  background: #961b96;
  min-width: 6rem;
}
.ant-switch-checked {
  background: #961b96;
}
// .ant-switch-handle {
// border: 3px solid #25aa9770;
// border-radius: 50%;
// background: #25aa9770;
// }
.ant-switch-inner {
  //switch text color
  color: white;
}

// ant styles restyle h1?
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}

.ant-upload.ant-upload-select-picture-card {
  border-radius: 50%;
  margin: 0;
  overflow: hidden;
}
